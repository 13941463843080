import { Button, Divider, Form, Input } from "antd";
import React from "react";
import { DataGetAction, DataRequestAction } from "../../../redux/actions/CommonHttp";
import { useDispatch } from "react-redux";

const ResetPasswordForm = ({ selectedUser, setModal }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const onFinish = (values) => {
    // Call the handleSubmit prop with form values
    let obj = {
      password: values.password,
      id: selectedUser ? selectedUser._id : null,
    };
    console.log("==values==", obj);
    dispatch(
      DataRequestAction({
        method: "PUT",
        apiName: "resetPasswordToNew",
        data: obj,
        requestType: "updateRecord",
        recordName: "drivers",
      })
    )
      .unwrap()
      .then((response) => {
        if (response.responseData?.status === "SUCCESS") {
          dispatch(
            DataGetAction({
              apiName: "getAllUser",
              data: "",
              requestType: "fetch",
              recordName: "drivers",
            })
          );
        }
      })
      .catch((error) => {
        console.error("Error", error);
      });
    form.resetFields();
    setModal(false);
    // handleSubmit(values);
    // form.resetFields(); // Reset fields after submission
  };

  const handleCancel = () => {
    form.resetFields(); // Reset fields on cancel
    setModal(false); // Close the modal
  };

  return (
    <div>
      <Form
        form={form} // Make sure the form instance is passed here
        name="resetPassword"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label="New Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please enter new password!",
            },
            {
              pattern: /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/,
              message: "Password must be at least 10 characters, include one uppercase letter, one number, and one special character.",
            },
          ]}
        >
            <div className="text-gray-500 mb-2 mt-[-5px]">Password must be at least 10 characters long, include one uppercase letter, one number, and one special character.</div>
          <Input.Password size="large" placeholder="Enter new password" />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: "Please enter confirm password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("The confirm passwords do not match with new password!"));
              },
            }),
          ]}
        >
          <Input.Password size="large" placeholder="Enter confirm password" />
        </Form.Item>

        <Divider style={{ marginBottom: 20 }} />

        <div className="flex justify-end">
          <Button type="primary" className="cancel-button font-roboto-bold text-lg p-5" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            className="save-button font-roboto-bold text-lg p-5"
            htmlType="submit"
            style={{ backgroundColor: "yellow", color: "black" }}
          >
            Update
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ResetPasswordForm;
