import { Button, Divider, Form, Input, message, Spin } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateUserProfile } from '../../../redux/reducers/authSlice'; // Adjust the import path accordingly

const ResetPasswordForm = ({ setModal, loading }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const handleSave = () => {
    const values = form.getFieldsValue();
    let obj = {
      password: values.newPassword,
      oldPassword: values.currentPassword,
    };
    console.log('obj===', obj);
    dispatch(updateUserProfile({ apiName: 'changePassword', data: obj }));
    form.resetFields();
    setModal(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setModal(false);
  };

  const validateNewPassword = (_, value) => {
    const currentPassword = form.getFieldValue("currentPassword");
    if (value && value === currentPassword) {
      return Promise.reject(new Error("New password must be different from the current password."));
    }
    return Promise.resolve();
  };

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('newPassword') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('The two passwords do not match!'));
    },
  });

  return (
    <div>
      <Spin spinning={loading}>
        <Form
          form={form}
          name="resetPassword"
          onFinish={handleSave}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Current Password"
            name="currentPassword"
            rules={[
              {
                required: true,
                message: "Please input your current password!",
              },
            ]}
          >
            <Input.Password size="large" placeholder="Enter current password..." />
          </Form.Item>

          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
              {
                required: true,
                message: "Please input your new password!",
              },
              { validator: validateNewPassword },
            ]}
          >
            <Input.Password size="large" placeholder="Enter new password..." />
          </Form.Item>

          <Form.Item
            label="Confirm New Password"
            name="confirmPassword"
            dependencies={['newPassword']}
            rules={[
              {
                required: true,
                message: "Please confirm your new password!",
              },
              validateConfirmPassword,
            ]}
          >
            <Input.Password size="large" placeholder="Confirm new password..." />
          </Form.Item>

          <Divider style={{ marginBottom: 20 }} />

          <div className="flex justify-end">
            <Button
              type="primary"
              className="cancel-button font-roboto-bold text-lg p-5"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="save-button font-roboto-bold text-lg p-5"
              htmlType="submit"
              style={{ backgroundColor: "yellow", color: "black" }}
              loading={loading}
            >
              Save
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default ResetPasswordForm;
